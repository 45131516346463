import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  BootstrapTable,
  TableHeaderColumn,
} from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { Link } from "react-router-dom";
import { getStudies, inviteUsers } from "../../actions/studiesActions";
import { Breadcrumbs } from "../../components/Breadcrumb";

export class Studies extends Component {
  constructor(props) {
    super(props);
    this.user = JSON.parse(localStorage.getItem("user"));
    this._inviteUsers = this._inviteUsers.bind(this);
    this.all_studies = [];
    this.state = {
      studies: null
    }
  }

  async componentDidMount() {
    let group_ip
    if (localStorage.user) {
      group_ip = JSON.parse(localStorage.user).group_id
    }
    await this.props.getStudies(group_ip);
    this.setState({ studies: this.props.studies });
    this.setState({ update: true });
  }

  _inviteUsers(study_id) {
    let index;
    this.props.studies.map((item, i) => {
      if (item.ID == study_id) {
        index = i;
        this.props.studies[i]["loading"] = true;
        this.setState({ update: true });
      }
    });

    inviteUsers(study_id).then((response) => {
      this.props.studies[index]["status"] = response.data.status;
      this.props.studies[index]["loading"] = false;
      this.setState({ update: true });
    });
  }

  infoEstudo(cell, item) {
    if (item.panelist_qty > 0) {
      return <Link to={"/studies/info/" + item.panelist_qty}>{cell}</Link>;
    } else {
      return <button className="btn btn-sm btn-success btn-round btn-block disabled">{cell}</button>;
    }
  }


  actButton(study_id, item, toggle) {

    let user = JSON.parse(localStorage.getItem("user"));

    return (item.loading ?
      <div className="text-center">
        <button className="btn btn-sm btn-success btn-round disabled">
          <span className="fa fa-refresh fa-spin"></span>
        </button>
      </div>
      : (user.group_id == 1 ? <div>
        <Link
          to={"/studies/edit/" + item.ID}
          className="btn btn-sm btn-success btn-round btn-block"
        >
          Editar
        </Link>
        <Link
          to={"/studies/info/" + item.ID}
          className="btn btn-sm btn-success btn-round btn-block"
        >
          Visualizar
        </Link>
        <a href={"https://emotions-ws.brandful.com.br/studies/exportEvents/" + item.ID} className="btn btn-sm btn-success btn-round btn-block">
          Download Eventos
        </a>
        <a href={"https://emotions-ws.brandful.com.br/studies/exportDuration/" + item.ID} className="btn btn-sm btn-success btn-round btn-block">
          Download Duração
        </a>
        <form action={"https://emotions-ws.brandful.com.br/studies/exportPDF/" + item.ID} method="post" target="_blank">
          <input type="hidden" name="token" value={localStorage.getItem("bfToken")} />
          <input type="hidden" name="user" value={localStorage.getItem("user")} />
          <button type="submit" className="btn btn-sm btn-success btn-round btn-block">
            Download PDF
          </button>
        </form>
      </div>
        : <div>
          <Link
            to={"/studies/info/" + item.ID}
            className="btn btn-sm btn-success btn-round btn-block"
          >
            Visualizar
          </Link>
        </div>)
    )
  }
  status(cell, item) {
    let status_icon = {
      available: (
        <span className="fa text-success fa-circle" title="Disponível"></span>
      ),
      processing: (
        <span
          className="fa text-warning fa-circle"
          title="Em processamento"
        ></span>
      ),
      unavailable: (
        <span className="fa text-default fa-circle" title="Não iniciado"></span>
      ),
      cancelled: (
        <span className="fa text-danger fa-circle" title="Cancelado"></span>
      ),
    };
    return status_icon[item.status];
  }

  thumbnail(cell, item) {
    return <img src={item.thumbnail} width="150" />;
  }

  createCustomInsertButton = (onClick) => {
    return (
      <Link
        to="/studies/create"
        className="btn btn-success btn-round"
        style={{ marginTop: "3px" }}
      >
        <i className="fa fa-plus"></i> Novo Estudo
      </Link>
    );
  };
  createCustomToolBar = (props) => {
    return (
      <div className="container-fluid">
        <div className="d-flex">
          {this.user.group_id == "1" ? (
            <Link
              to="/studies/create"
              className="btn btn-success btn-round"
              style={{ marginTop: "3px" }}
            >
              <i className="fa fa-plus"></i> Novo Estudo
            </Link>
          ) : null}
          <div className="col-sm-4">{props.components.searchPanel}</div>
        </div>
      </div>
    );
  };


  render() {
    const { studies } = this.state;
    return (
      <div>
        <Header />
        <Breadcrumbs items={[{ "className": 'active', 'title': 'Estudos' }]} />
        <div className="container">
          <h3 >
            <strong>Gerenciamento de Estudos</strong>
          </h3>

          <div>
            {studies === null ? (
              <p className="text-center p-4">
                <span className="fa fa-refresh fa-spin fa-2x"></span>
              </p>
            ) : (
              <div className=" mainrow">
                <div className="mainTable">
                  <BootstrapTable
                    data={studies}
                    hover
                    search
                    options={{
                      noDataText: "Nenhum estudo até o momento",
                      insertBtn: this.createCustomInsertButton,
                      toolBar: this.createCustomToolBar,
                    }}
                    searchPlaceholder="Procurar..."
                    pagination
                  >
                    <TableHeaderColumn
                      isKey
                      dataField="ID"
                      width="50"
                      dataAlign="center"
                      dataSort={true}
                    >
                      ID
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="thumbnail"
                      width="150"
                      columnClassName="campaignTitle"
                      dataFormat={this.thumbnail}
                    ></TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="name"
                      columnClassName="text-uppercase"
                      dataSort={true}
                    >
                      Estudo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="advertiser"
                      dataSort={true}
                      columnClassName="text-uppercase"
                    >
                      Anunciante
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="product"
                      width="100"
                      dataAlign="center"
                      dataSort={true}
                    >
                      Produto
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="videos_qty"
                      width="100"
                      dataAlign="center"
                      dataSort={true}
                    >
                      Vídeos
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="status"
                      width="90"
                      dataAlign="center"
                      dataFormat={this.status}
                    >
                      Status
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="panelist_qty"
                      width="120"
                      dataAlign="center"
                      dataSort={true}
                    >
                      Participantes
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="ID"
                      width="200"
                      dataFormat={this.actButton}
                      formatExtraData={this._toggleStudy}

                    ></TableHeaderColumn>

                  </BootstrapTable>
                </div>
                <div className="col-md-2 mainDescription">
                  <ul className="list-unstyled pt-5 small">
                    <li>
                      <span
                        className="fa text-success fa-circle"
                        title="Disponível"
                      ></span>{" "}
                      Disponível
                    </li>
                    <li>
                      <span
                        className="fa text-warning fa-circle"
                        title="Em processamento"
                      ></span>{" "}
                      Em processamento
                    </li>
                    <li>
                      <span
                        className="fa text-default fa-circle"
                        title="Não iniciado"
                      ></span>{" "}
                      Não iniciado
                    </li>

                    <li>
                      <span className="fa text-danger fa-circle" title="Cancelado"></span>{" "}
                      Cancelado
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  studies: store.studies.list,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getStudies }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Studies);
