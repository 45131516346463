import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { getVideos } from "../../../actions/videosActions";
import { changeStudyData } from "../../../actions/studiesActions";
import video_model from "../../../models/video";

export class StudyVideos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      videos: null,
    };
    let group_id = JSON.parse(localStorage.user).group_id
    let user_id = JSON.parse(localStorage.user).user_id
    this.props.getVideos();
    this.onChange = this.onChange.bind(this);
    this.addVideo = this.addVideo.bind(this);
  }

  gridVideos() {
    let videos = [];
    let studyData = this.props.studyData;
    if (typeof this.props.videos != "undefined") {
      studyData.videos.map((video) => {
        if (video.video_id) {
          videos.push(video.video_id);
        }
      });

      this.props.videos.map((video) => {
        if (videos.indexOf(video.video_id) == -1) {
          videos.push(video);
        }
      });
      this.setState({ videos });
    }
  }

  addVideo(videoData) {
    let videos = this.state.videos;

    videos.map((video, key) => {
      if (video.video_id == videoData.video_id) {
        videos.splice(key, 1);
      }
    });

    let data = this.props.studyData;
    data.videos.push(videoData);

    if (data.videos[0].video_id == "") {
      data.videos.splice(0, 1);
    }

    this.props.changeStudyData(data);
    this.setState({ update: true });
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  addButton(cell, item, extra) {
    return (
      <button
        className="btn btn-sm btn-success btn-round"
        type="button"
        onClick={() => {
          extra(item);
        }}
      >
        Adicionar
      </button>
    );
  }

  onChange(name, val, i) {
    let data = this.props.studyData;
    data.videos[i][name] = val;
    this.props.changeStudyData(data);
    this.setState({ update: true });
  }

  _novoVideo = () => {
    this.setState({ showModal: true });
  };

  _removeVideo = (index) => {
    let data = this.props.studyData;
    this.state.videos.push(data.videos[index]);
    data.videos.splice(index, 1);

    if (data.videos.length == 0) {
      data.videos.push(video_model);
    }
    this.props.changeStudyData(data);
    this.setState({ update: true });
  };

  thumbnail(cell, item) {
    return <img src={item.thumbnail} width="150" />;
  }

  render() {
    const { studyData, loading_videos } = this.props;
    const { videos, showModal } = this.state;
    if (!loading_videos && videos == null) {
      this.gridVideos();
    }
    return (
      <div>
        <h3 className="title m-0 h4"> Vídeos escolhidos</h3>
        {studyData.total_views === 0 ?
          <p>
            <button
              className="btn btn-success btn-round"
              style={{ marginTop: "3px" }}
              type="button"
              onClick={this._novoVideo}
            >
              <i className="fa fa-plus"></i> Adicionar vídeo
            </button>
          </p> : null}
        {studyData.videos.length > 0 && studyData.videos[0].video_id ? (
          <div style={{ overflowX: "auto", }}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th width="10">ID</th>
                  <th width="250">Miniatura</th>
                  <th width="100">Tempo</th>
                  <th>Nome</th>
                  <th width="50"></th>
                </tr>
              </thead>
              <tbody>
                {studyData.videos.map((item, i) => (
                  <tr key={i}>
                    <td>{item.video_id}</td>
                    <td><img src={item.thumbnail} width="250" /></td>
                    <td>{item.time}</td>
                    <td>{item.name}</td>

                    <td>
                      {item.total_views === 0 ?
                        <button className="btn btn-danger btn-round" type="button" onClick={() => this._removeVideo(i)}>
                          Remover
                        </button>
                        :
                        <button className="btn btn-danger btn-round disabled" type="button">
                          Remover
                        </button>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}

        {showModal ? (
          <div>
            <div className="modal fade show" style={{ display: "block" }}>
              <div className="modal-dialog modal-lg">
                <div className="modal-content" style={{ height: "90vh", overflowY: "auto", overflow: "hidden" }}>
                  <div className="modal-header">
                    <h5 className="modal-title">
                      Adicionar vídeo para esse estudo
                    </h5>
                    <button
                      type="button"
                      className="close"
                      onClick={() => this.closeModal()}
                    >
                      <i className="material-icons">clear</i>
                    </button>
                  </div>
                  <div className="modal-body" style={{ overflowY: "auto" }}>
                    <BootstrapTable
                      data={videos}
                      hover
                      search
                      options={{ noDataText: "Nenhum vídeo encontrado" }}
                      searchPlaceholder="Procurar..."
                      pagination
                    >
                      <TableHeaderColumn
                        isKey
                        dataField="video_id"
                        width="50"
                        dataAlign="center"
                        dataSort={true}
                      >
                        ID
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField="name">
                        Nome
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="thumbnail"
                        dataFormat={this.thumbnail}
                      >
                        Thumbnail
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        formatExtraData={this.addVideo}
                        dataField="video_id"
                        width="100"
                        dataAlign="center"
                        dataFormat={this.addButton}
                      ></TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal-backdrop fade show"
              onClick={() => this.closeModal()}
            ></div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  studyData: store.studies.current,
  videos: store.videos.all_videos,
  loading_videos: store.videos.loading_get_videos,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getVideos, changeStudyData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StudyVideos);